/* Container styling */
#why-choose-us-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  background-color: #f9f9f9;
}

/* Why Choose Us section styling */
.why-choose-us-container h3 {
  font-weight: bold;
  margin-bottom: 30px; /* Added space between header and boxes */
  font-size: 24px; /* Slightly increased font size */
}

.list-style {
  display: flex;
  flex-wrap: wrap;
}

.list-style ul {
  padding-left: 0;
  list-style-type: none;
}

.list-style ul li {
  background: #f9f9f9;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px; /* Added more space between the boxes */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-left: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  color: #333; /* Made the visible words darker */
}

.list-style ul li:hover {
  background-color: #e6e6e6;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.list-style ul li .expand-btn {
  background-color: #007bff;
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.list-style ul li .expand-btn:hover {
  background-color: #0056b3;
}

.list-style ul li .expanded-text {
  display: inline-block;
  margin-left: 10px;
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .list-style {
    flex-direction: column;
  }

  .col-lg-6,
  .col-sm-6,
  .col-xs-12 {
    width: 100%;
  }
}
