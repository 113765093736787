/* gallery-image.css */

/* Styling for the portfolio item */
.portfolio-item {
  position: relative;
  overflow: hidden; /* Ensure image does not overflow the container */
  border-radius: 8px;
  border: 2px solid #ddd;
  height: 100%;
  margin: 10px; /* Added margin for spacing */
}

/* Styling for the hover background */
.hover-bg {
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 8px; /* Ensure hover background has rounded corners */
}

/* Styling for the hover text */
.hover-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  text-align: center;
  transition: opacity 0.3s ease; /* Smooth transition for hover text */
}

/* Hover effect for hover text */
.hover-bg:hover .hover-text {
  opacity: 1;
}

/* Responsive image styling */
.img-responsive {
  width: 100%;
  height: 100%; /* Maintain aspect ratio */
  display: block;
  border-radius: 8px;
  object-fit: cover; /* Ensures the image covers the entire area */
}
