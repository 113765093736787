#services {
  padding: 60px 0;
  background-color: #1b2d3d; /* Background color */
}

.main-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; /* Add padding for small screens */
}

.section-title {
  margin-bottom: 40px;
}

.section-title h2 {
  font-weight: bold;
  color: #ffffff; /* White color for title for contrast */
}

.section-title p {
  color: #b0b0b0; /* Lighter gray for subtext */
}

.service-container {
  display: flex;
  flex-wrap: wrap; /* Responsive wrapping */
  justify-content: space-between;
  gap: 20px;
}

.service-card {
  background-color: #2c3e50; /* Dark background for card */
  border: 1px solid #34495e; /* Darker border for contrast */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-align: center;
  width: 100%; /* Full width for smaller screens */
  max-width: 350px; /* Limit the width for larger screens */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .service-card {
    width: calc(33.333% - 20px); /* 3 cards per row on larger screens */
  }
}

.service-card:hover {
  background-color: #34495e; /* Slightly lighter background on hover */
  border-color: #1abc9c; /* Highlighted border color on hover */
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-image {
  width: 120px; /* Set uniform image size */
  height: 120px;
  object-fit: cover;
  border-radius: 50%; /* Make the image circular */
  margin-bottom: 15px;
}

.service-desc h3 {
  font-weight: bold;
  margin-bottom: 10px;
  color: #ecf0f1; /* Light color for title */
}

.service-desc p {
  color: #bdc3c7; /* Light gray for text */
}
