/* Navigation */
#menu {
  padding: 10px 0;
  transition: all 0.8s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff; /* Background color of navbar */
}

#menu.navbar-default {
  border-color: rgba(231, 231, 231, 0);
}

#menu .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensure responsiveness */
}

#menu .navbar-header {
  display: flex;
  align-items: center;
  flex: 1; /* Let it take necessary space */
}

#menu .navbar-header .navbar-brand {
  display: flex;
  align-items: center;
  padding: 0;
}

#menu .navbar-header .navbar-brand img {
  max-height: 50px;
  margin-right: 10px;
}

#menu .navbar-header .navbar-toggle {
  border-radius: 4px;
  background-color: transparent;
  border: none;
}

#menu .navbar-header .navbar-toggle .icon-bar {
  background-color: #333; /* Color of hamburger icon bars */
}

#menu.navbar-default .navbar-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1; /* Let it take the remaining space */
}

#menu.navbar-default .navbar-nav > li {
  margin: 0 10px; /* Adjust spacing between menu items */
}

#menu.navbar-default .navbar-nav > li > a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 15px;
  border-radius: 4px;
  position: relative;
  transition: color 0.3s, background-color 0.3s;
}

#menu.navbar-default .navbar-nav > li > a:hover,
#menu.navbar-default .navbar-nav > li > a:focus {
  color: #fff;
  background-color: #6372ff; /* Background color on hover */
  text-decoration: none;
}

#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -5px; /* Adjust to position underline */
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}

#menu.navbar-default .navbar-nav > li > a:hover:after,
#menu.navbar-default .navbar-nav > li > a:focus:after {
  width: 100%;
}

/* Dropdown Position Adjustment */
#menu.navbar-default .navbar-nav > li.dropdown {
  position: relative;
}

#menu.navbar-default .navbar-nav .dropdown:hover > .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease;
}

#menu.navbar-default .dropdown-menu {
  background-color: #e9eaf0; /* Background color of dropdown */
  border: 1px solid rgba(231, 231, 231, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  width: 200px; /* Increased width */
}

#menu.navbar-default .dropdown-menu > li {
  margin: 5px 0;
}

#menu.navbar-default .dropdown-menu > li > a {
  color: #131212; /* Text color inside dropdown */
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

#menu.navbar-default .dropdown-menu > li > a:hover {
  background-color: #4cb5cf; /* Hover background color inside dropdown */
  color: #fff;
}

/* Adjusting the caret to the right of the "Demo" text */
#menu.navbar-default .navbar-nav > li.dropdown > a .caret {
  margin-left: 5px;  /* Space between text and caret */
  display: inline-block;  /* Ensures caret is aligned with text */
  vertical-align: middle;  /* Aligns caret vertically with the text */
}

@media (max-width: 768px) {
  #menu .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; /* Make it full-width for mobile */
  }

  #menu .navbar-nav > li {
    margin: 10px 0;
  }
}
