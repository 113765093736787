/* Container to hold all team images */
#main-team-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  max-width: 1500px; /* Adjust based on your design */
  margin: 0 auto; /* Centering the container */
  padding: 20px; /* Padding inside the container */
  background-color: #ffffff; /* White background color for the container */
  border-radius: 12px; /* Rounded corners for the container */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow effect */
}

.team-container {
  position: relative;
  width: 1360px;
  height: 950px; /* Adjust height to accommodate all images */
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden; /* Ensure images don't overflow the container */
  background-color: #1b2d3d; /* Light background to enhance wooden frame effect */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Common thumbnail styling for all images (center, surrounding, and edge) */
.thumbnail {
  position: absolute;
  border: 12px solid transparent; /* Create space for the wooden frame */
  border-image: url('../img/frame.png') 30 round; /* Add realistic wooden frame */
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  overflow: hidden; /* Ensure image and caption stay within the border */
}

/* Hover effect for all images */
.thumbnail:hover {
  transform: scale(1.2); /* Increase size on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.thumbnail:hover .team-caption {
  opacity: 1; /* Show caption on hover */
  transform: translateY(0); /* Move caption up to be visible */
}

/* Styling for the images within the thumbnails */
.team-img {
  width: 100%;
  height: 100%;
  object-fit:cover;
}

/* Center image specific styling */
.center-thumbnail {
  width: 220px; /* Larger size for the central image */
  height: 260px;
}

/* Common styling for team captions */
.team-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  padding: 5px;
  text-align: center;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(100%); /* Move below the image */
}

/* Styling for the team name and job */
.team-name {
  font-weight: bold;
  margin: 0;
}

.team-job {
  font-weight: bold;
  color: #555;
  margin: 0;
}

/* General styles for the team section */
#team {
  padding: 10px 0;
}

#team h2 {
  margin-bottom: 10px;
  /* font-size: 2.5rem; */
  /* font-weight: bold; */
  text-align: center; /* Center the heading */
  margin-left: 200px;
  color: #333;
  text-transform: uppercase;
}