#contact {
  padding: 80px 15px;
  background: linear-gradient(to right,  #1b2d3d 0%, #38397b 100%);
  color: rgba(255, 255, 255, 0.75);
}

#contact .section-title {
  margin-bottom: 40px;
  text-align: center;
}

#contact h2 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  position: relative;
}

#contact .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

#contact h3 {
  color: #fff;
  margin-top: 40px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400;
}

#contact form {
  padding-top: 20px;
}

#contact .btn-custom {
  margin: 30px 0;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  transition: background 0.3s ease;
}

#contact .btn-custom:hover {
  color: #4caf50;
  background: #fff;
}

#contact .form-control {
  display: block;
  width: 100%;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 1.5;
  color: #444;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: none;
  transition: border-color 0.3s ease;
}

#contact .form-control:focus {
  border-color: #999;
  outline: 0;
}

#contact .contact-item {
  margin: 20px 0;
}

#contact .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
}

#contact .contact-item i.fa {
  margin-right: 10px;
}

#contact .social {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 50px;
  margin-top: 50px;
  text-align: center;
}

#contact .social ul {
  padding-left: 0;
}

#contact .social ul li {
  display: inline-block;
  margin: 0 10px;
}

#contact .social i.fa {
  font-size: 22px;
  width: 48px;
  height: 48px;
  padding: 12px;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}

#contact .social i.fa:hover {
  color: #4caf50;
  background: #fff;
}

#footer {
  padding: 20px 15px;
  background: #1f386e;
  color: #fff;
}

#footer p {
  margin: 0;
}

#footer a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 767px) {
  #contact .col-md-8,
  #contact .col-md-4 {
    margin-bottom: 20px;
  }
  
  #contact .social ul {
    padding-left: 0;
    margin: 0;
  }

  #contact .social ul li {
    display: block;
    margin-bottom: 10px;
  }
}
