.features-title-container {
  text-align: center;
  margin-top: 50px;
}

.features-title {
  font-size: 2rem;
  font-weight: bold;
  position: relative;
}

.features-title::after {
  content: '';
  display: block;
  width: 100px;
  height: 4px;
  background: #007bff;
  margin: 10px auto 0;
  border-radius: 2px;
}

.features-section {
  padding: 60px 20px;
  background-color: #f1f1f1;
}

.features-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1800px; /* Increased width */
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.features-image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.features-image img {
  width: 100%;
  height: 300px; /* Set uniform height */
  object-fit: cover; /* Ensures images are cropped to fill the container */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.features-image:hover img {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.features-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feature-item {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.feature-item:hover {
  border-color: #007bff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}

.feature-icon {
  font-size: 35px;
  color: #007bff;
  margin-right: 20px;
}

.feature-info {
  flex: 1;
}

.feature-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

.feature-text {
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: 0;
  opacity: 0;
  margin: 0;
}

.feature-text.expanded {
  max-height: 500px;
  opacity: 1;
}

.expand-collapse-btn {
  font-size: 15px;
  margin-top: 10px;
  color: rgb(26, 48, 221);
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.expand-collapse-btn:hover {
  background-color: #a8c5e6;
}

@media screen and (min-width: 768px) {
  .features-container {
    flex-direction: row;
  }

  .features-image {
    flex: 1;
  }

  .features-content {
    flex: 2;
  }
}

@media screen and (max-width: 768px) {
  .features-container {
    flex-direction: column;
    max-width: 100%; /* Full width on small screens */
  }

  .features-image {
    width: 100%; /* Full width for images */
    margin-bottom: 20px;
  }
}
